import React,{useState, useEffect} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import useAxios from '../plugins/useAxios'

const SettingsScreen = () => {
    const [recipientForm, setRecipientForm] = useState({
        name: '',
        email: '',
    })
    const [recipients,setRecipients] = useState([]);
    const [visible, setVisible] = useState(false)
    const[error, setError] = useState(false)

    const onRowEditComplete = (e) => {
        console.log(e)
        let _recipients = [...recipients];
        let { newData, index } = e;

        _recipients[index] = newData;

        updateRecipient(newData, index);
    }

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const remove = (data) => {
        return (
            <div className="cursor-pointer hover:!bg-gray-200 rounded-full transition" onClick={() => removeRecipient(data)}>
                <i className="pi pi-trash p-2" />
            </div>
            
        )
    }

    const renderFooter = () => {
        return (
            <div>
                <Button label="Annulla" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
                <Button className="!bg-stone-500 w-full !border-0" label="Crea" icon="pi pi-check" onClick={() => updateRecipient(recipientForm)} />
            </div>
        );
    }

    const updateRecipient = (recipient, listIndex) => {
        if (recipient.email === ''){
            setError(true)
            return
        }
        useAxios.post(`recipient${recipients.length > 0 ? ('/' + recipient.id) : ''}`, recipient).then(res => {
            if(!recipient.id){
                getRecipients()
                setRecipients((oldArray) => [...oldArray, {name: recipient.name, email: recipient.email}]);
            }
            else {
                let _recipients = [...recipients];
        
                _recipients[listIndex] = recipient;
                setRecipients(_recipients);
            }
            
            setVisible(false)
        })
        .catch(err => {

        })
        
    }

    const removeRecipient = (recipient) => {
        useAxios.delete('recipient/' + recipient.id, recipient).then(res => {
            let filtered = recipients.filter(item => item.id !== recipient.id)
            setRecipients(filtered);
        })
    }

    const getRecipients = () => {
        useAxios.get('recipients').then(res => {
            setRecipients(res.data)
        })
    }

    useEffect(() => {
        getRecipients()
    }
    ,[]);
    
    return (
        <div className="lg:p-12 p-6">
            <div className="text-3xl font-bold mb-12">Impostazioni</div>
            <div>
                <div className="text-xl font-medium mb-2 flex items-center">
                    <div>Destinatari mail</div>
                    <Button onClick={() => setVisible(true)} className="!bg-stone-500 !py-1 !border-0 !ml-2" label="Nuovo" ></Button>
                </div>
                <div className="lg:w-fit w-full mt-4">
                    <DataTable value={recipients} editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete} responsiveLayout="stack">
                        <Column field="name" header="Nome" editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
                        <Column field="email" header="Email" editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
                        <Column rowEditor bodyStyle={{ textAlign: 'center' }}></Column>
                        <Column field="id" body={remove} ></Column>
                    </DataTable>
                </div>

            </div>
            <Dialog className="!w-5/6 lg:!w-1/3" header="Nuovo destinatario" visible={visible} style={{ width: '50vw' }} footer={renderFooter} onHide={() => setVisible(false)}>
                <div className="p-8 rounded-xl bg-white">
                    <div>
                        <div className="mb-2">Nome (opzionale)</div>
                        <InputText placeholder="Inserisci il nome del destinatario" className={"w-full "} value={recipientForm.name} onChange={(e) => setRecipientForm({...recipientForm, name: e.target.value})} />
                    </div>
                    <div className="mt-4">
                        {error && (
                            <div className="text-red-700 font-medium text-sm my-2">*Il campo email deve contenere un destinatario</div>
                        )}
                        <div className="mb-2">Email*</div>
                        <InputText placeholder="Inserisci la mail del destinatario" className={"w-full "} value={recipientForm.email} onChange={(e) => setRecipientForm({...recipientForm, email: e.target.value})} />
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default SettingsScreen;
