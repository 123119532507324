import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useState, useEffect } from 'react';
import useAxios from '../plugins/useAxios';
import socketIOClient from "socket.io-client";
import { useNavigate } from "react-router-dom";
import { Paginator } from "primereact/paginator";

const ExitScreen = () => {
    const header = () => {
        return (
            <div className="flex justify-end">
                <span className="p-input-icon-left mr-2">
                    <i className="pi pi-search" />
                    <InputText value={q} onChange={onSearchChange} placeholder="Cerca targa" />
                </span>
                <Button type="button" icon="pi pi-filter-slash"  className="p-button-outlined" onClick={clearFilter} />
            </div>
        )
    }

    const hide = (data) => {
        return (
            <div className="cursor-pointer hover:!bg-gray-200 rounded-full transition" onClick={() => hideEvent(data)}>
                <i className="pi pi-eraser p-2" />
            </div>
            
        )
    }
    
    const date = (data) => {
        return(
            <div>{(new Date(parseInt(data.timestamp))).toLocaleString()}</div>
        )
    }
    
    const image = (data) => {
        return(
            <img alt="" src={useAxios.getUri().replace('api/','') +  data.imagePath} />
        )
    }

    const navigate = useNavigate();
    const [filters,setFilters] = useState([])
    const [search,setSearch] = useState('')
    const [events, setEvents] = useState([])
    const [q, setQ] = useState('');
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(50);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tagName, setTagName] = useState('');

    const footer = `Totale di  ${events ? events.length : 0} eventi.`;
    
    const onPageChange = (event) => {
        console.log(event)
        setPage(event.page);
        setRows(event.rows);
    };
      
    const clearFilter = ()=> {
        setQ('')
    }

    const onSearchChange = (e) => {
        setQ(e.target.value)
    }

    const hideEvent = (event) => {
        useAxios.post('event/' + event._id, event).then(res => {
            let filtered = events.filter(item => item._id !== event._id)
            let _filtered = filters.filter(item => item._id !== event._id)
            setEvents(filtered);
            setFilters(_filtered);
        })
    }

    const fetchEvents = (tagName) => {
        useAxios.get(`events?direction=out&tagName=${tagName}&page=${page}&pageSize=${rows}&q=${q}`)
        .then((res) => {
            console.log('cars',totalRecords)
            setEvents(res.data.data)
            setTotalRecords(res.data.totalRecords)
        })
        .catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        const tagName = localStorage.getItem('site')
        if (!tagName){
            navigate('/sites')
            return
        }
        setTagName(tagName)
        // const socket = socketIOClient(useAxios.getUri().replace('/api/',''));
        // socket.on("event added", data => {
        //     if(data.tagName === tagName){
        //         fetchEvents(tagName)
        //     }
        // })
        fetchEvents(tagName)
        // return () => {
        //     socket.disconnect();
        // }
    },[rows,page,q])

    const first = page * rows;

    return (
        <div className="lg:p-12 p-6">
            <div className="text-3xl font-bold mb-6 capitalize">Uscite {tagName == 'finale_park' ? 'Area Camper' : tagName}</div>
            <div className="datatable-templating">
                <DataTable value={(search !== '') ? filters : events} header={header} footer={footer} responsiveLayout="stack">
                    {/* <Column field="_id" header="ID"></Column> */}
                    <Column field="plate" header="Targa"></Column>
                    <Column field="country" header="Paese"></Column>
                    <Column field="direction" header="Direzione"></Column>
                    <Column field="timestamp" header="Orario d'uscita" body={date}></Column>
                    <Column field="image" header="Immagine" body={image}></Column>
                    <Column field="id" header="Elimina" body={hide} ></Column>
                </DataTable>
                <Paginator first={first} rows={rows} totalRecords={totalRecords} onPageChange={onPageChange} /> {/* Replace 100 with your total number of records */}
            </div>
        </div>
    )
} 
export default ExitScreen;