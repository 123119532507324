import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import {Button } from "primereact/button";
import { useState } from 'react';
import useAxios from "../plugins/useAxios";
import { useNavigate } from "react-router-dom";
import Logo from '../components/Logo'

const LoginScreen = ({setLogged}) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [error,setError] = useState(false)
    const [loginForm, setForm] = useState({
        username: '',
        password: ''
    })

    const login = () => {
        setLoading(true)
        useAxios.post('login', loginForm).then(response => {
            setLogged(true)
            const tagSite = localStorage.getItem('site')
            if (tagSite) navigate('/entrance')
            else navigate('/sites')
            
        })
        .catch(err => {
            console.log(err.data)
            setError(true)
        })
        .finally(()=> {
            setLoading(false)
        })
    }

    return (
        <div className="w-full h-screen flex justify-center items-center bg-gray-800">
            <div className="p-8 rounded-xl bg-white">
                <div className="flex justify-center mb-4">
                    <Logo />
                </div>
                {error && (
                    <div className="text-red-700 font-medium text-sm mb-2">*Credenziali errate</div>
                )}
                <div>
                    <div className="mb-2">Nome utente</div>
                    <InputText placeholder="Inserisci il tuo nome utente" className={"w-full " + (error ? 'p-invalid block' : '') } value={loginForm.username} onChange={(e) => setForm({...loginForm, username: e.target.value})} />
                </div>
                <div className="mt-4">
                    <div className="mb-2">Password</div>
                    <Password placeholder="Inserisci la tua password"  className={'w-full ' + (error ? 'p-invalid block' : '')} feedback={false} toggleMask={true} value={loginForm.password} onChange={(e) => setForm({...loginForm, password: e.target.value})} />
                </div>
                <div className="mt-8">
                    <Button loading={loading} onClick={login} className="!bg-stone-500 w-full !border-0" label="Accedi" />
                </div>
            </div>
        </div>
    )   
}

export default LoginScreen;