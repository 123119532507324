import { useNavigate } from "react-router-dom";
import { NavLink } from 'react-router-dom'

const NavBarElement = ({text,route,setVisible}) => {
    
    return (
        <NavLink onClick={() => setVisible ? setVisible(false) : null} className={({ isActive }) => ((isActive ? '!bg-gray-700' : 'link') + ' transition cursor-pointer hover:bg-gray-700 rounded-xl p-4 my-2 text-xl font-light tracking-wider')} to={route}>{text}</NavLink>
    )
}

export default NavBarElement;