import React, {useState} from "react";
import NavBarElement from "./NavBarElement";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { confirmDialog } from 'primereact/confirmdialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import Logo from "../Logo";
import useAxios from "../../plugins/useAxios";
import { useNavigate } from "react-router-dom";

const NavBar = ({setLogged}) => {
    const navigate = useNavigate()
    const [visible,setVisible] = useState(false)
    
    const confirm = () => {
        confirmDialog({
            message: 'Sei sicuro di voler uscire?',
            header: 'Esci',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Conferma',
            acceptClassName: '!border-0 !bg-stone-500',
            accept: () => logout(),
            reject: () => {}
        });

    }

    const logout = () => {
        useAxios.post('logout').then(res => {
            setLogged(false)
            navigate('/login')
            localStorage.removeItem('site')
        })
        .catch(err => {
            console.log(err)
        })
    }

    return (
        <div>
            <div className="!h-screen w-64 bg-gray-800 text-white flex-col p-6 lg:flex hidden">
                <div className="flex justify-center mb-4">
                    <img alt="brand logo" src={require('../../assets/logo-fa.png')} />
                </div>
                <div className="flex flex-col h-3/4">
                    <NavBarElement text={'Ingresso'} route={'/entrance'} />
                    <NavBarElement text={'Uscita'} route={'/exit'} />
                    <NavBarElement text={'Let. Sconosciute'} route={'/unknown'} />
                    <NavBarElement text={'Parcheggio'} route={'/parking'} />
                    <NavBarElement text={'Cambia Sito'} route={'/sites'} />
                </div>
                <div className='flex-1 flex flex-col justify-end'>
                <NavBarElement setVisible={setVisible} text={'Impostazioni'} route={'/settings'} />
                    <div onClick={() => confirm()} className="cursor-pointer hover:bg-gray-700 rounded-xl p-4 text-xl font-light tracking-wider">Esci
                        <i className="pi pi-sign-out ml-2"></i>
                    </div>
                </div>
                <div className="flex justify-center mt-4">
                    <Logo />
                </div>
            </div>
            <div className="h-24 bg-gray-800 text-white flex p-6 lg:invisible visible items-center z-40 fixed w-full top-0 left-0" >
                <i className="pi pi-align-justify !text-2xl" onClick={(e) => setVisible(true)}></i>
                <Sidebar visible={visible} onHide={() => setVisible(false)} className="!w-64 h-[calc(100vh-72px)] !bg-gray-800 !text-white p-6 lg:hidden block">
                    <div className="h-full flex flex-col">
                        <div className="flex justify-center mb-4">
                            <img alt="brand logo" src={require('../../assets/logo-fa.png')} />
                        </div>
                        <div className="flex flex-col ">
                            <NavBarElement setVisible={setVisible} text={'Ingresso'} route={'/entrance'} />
                            <NavBarElement setVisible={setVisible} text={'Uscita'} route={'/exit'} />
                            <NavBarElement setVisible={setVisible} text={'Let. Sconosciute'} route={'/unknown'} />
                            <NavBarElement setVisible={setVisible} text={'Parcheggio'} route={'/parking'} />
                            <NavBarElement setVisible={setVisible} text={'Cambia Sito'} route={'/sites'} />
                        </div>
                        <div className='flex-1 flex flex-col justify-end'>
                            <NavBarElement setVisible={setVisible} text={'Impostazioni'} route={'/settings'} />
                            <div onClick={() => confirm()} className="cursor-pointer hover:bg-gray-700 rounded-xl p-4 text-xl font-light tracking-wider">Esci
                                <i className="pi pi-sign-out ml-2"></i>
                            </div>
                        </div>
                        <div className="flex justify-center mt-4">
                            <Logo />
                        </div>
                    </div>

                </Sidebar>
            </div>
            <ConfirmDialog />
        </div>

    )
}

export default NavBar;