import './App.css';
import {  Routes, Route, useNavigate } from "react-router-dom";
import { useEffect,useState } from 'react';

import NavBar from './components/layout/NavBar';

import EntranceScreen from './screens/EntranceScreen'
import ExitScreen from './screens/ExitScreen'
import ParkingAreaScreen from './screens/ParkingAreaScreen'
import LoginScreen from './screens/LoginScreen'
import SettingsScreen from './screens/SettingsScreen'
import SiteSwitchScreen from './screens/SiteSwitchScreen';
import UnknownScreen from './screens/UnknownScreen';

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import useAxios from './plugins/useAxios';

const App = () => {
  const [isLogged, setLogged] = useState(false)
  const navigate = useNavigate()

  useEffect(()=> {
    document.title = 'Plate Verifier'
    const auth = () => {
      useAxios.get('auth').then(response =>{
        setLogged(true)
        const tagSite = localStorage.getItem('site')
        if (tagSite) navigate('/entrance')
        else navigate('/sites')
      })
      .catch(err =>{
        setLogged(false)
        console.log(err)
        navigate('/login')
        
      })
    };
    auth()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='flex flex-col lg:flex-row'> 
      {isLogged ? (
        <>
          <NavBar setLogged={setLogged} />
          <div className="min-h-screen flex-1 overflow-scroll max-h-screen lg:pt-0 pt-24">
            <Routes>
              <Route index path="/sites" element={<SiteSwitchScreen />}>
              </Route>
              <Route path="/entrance" element={<EntranceScreen />}>
              </Route>
              <Route path="/exit" element={<ExitScreen />}>
              </Route>
              <Route path="/unknown" element={<UnknownScreen />}>
              </Route>
              <Route path="/parking" element={<ParkingAreaScreen />}>
              </Route>
              <Route path="/settings" element={<SettingsScreen />}>
              </Route>
            </Routes>
          </div>
        </>
      ) : 
        <Routes>
          <Route path="/login" element={<LoginScreen setLogged={setLogged} />}>
          </Route>
        </Routes>
      }
    </div>  
  );
}

export default App;
