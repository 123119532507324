import useAxios from "../../plugins/useAxios"
import { useNavigate } from "react-router-dom";

const SiteCard = ({item}) => {
    const navigate = useNavigate();

    const selectSite = () => {
        localStorage.setItem('site', item.tagName)
        console.log(localStorage.getItem('site', item.tagName))
        navigate('/entrance')
    }

    console.log(useAxios.getUri())
    const imgUri = (`${useAxios.getUri().replace('api/','')}${item.image}`)

    return (
        <div onClick={() => selectSite()} className="border-gray-200 rounded-2xl md:w-1/3 w-full bg-white box-border p-6 md:mr-4 mb-4 cursor-pointer transition hover:opacity-80">
            <div className="flex flex-col">
                <div style={{backgroundImage: `url(${imgUri})`}} className="h-64 mb-8 rounded-xl bg-cover" />
                <div className="text-xl mb-4">{item.title}</div>
                <div className="text-md">{item.description}</div>
            </div>
            
        </div>
    )
}

export default SiteCard;