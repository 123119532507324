import { useEffect, useState } from 'react';
import useAxios from "../plugins/useAxios";
import SiteCard from "../components/SwitchScreen/SiteCard";

const SiteSwitchScreen = () => {
    const [sites,setSites] = useState([])

    const getSites = () => {
       useAxios.get('auth-profile').then((res) => {
        console.log(res)
        setSites(res.data.enabledSites)
       })
       .catch((err) => {

       })
    }

    useEffect(() => {
        getSites()
    },[])

    return (
        <div className="w-full min-h-screen  items-center bg-gray-800 absolute top-0 left-0 md:p-20 p-8 z-50">
            <div className="text-4xl text-white mb-12">Seleziona il sito da visualizzare</div>
            <div className="flex md:flex-row flex-col flex-wrap mb-4 w-full">
                {sites.map((item, key) => {
                    return ( <SiteCard key={key} item={item} /> )
                })}
            </div>
        </div>
    )   
}

export default SiteSwitchScreen;