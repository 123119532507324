import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useState, useEffect } from 'react';
import useAxios from '../plugins/useAxios';
import socketIOClient from "socket.io-client";
import { useNavigate } from "react-router-dom";
import { Paginator } from "primereact/paginator";

const ParkingAreaScreen = () => {

    const hide = (data) => {
        return (
            <div className="cursor-pointer hover:!bg-gray-200 rounded-full transition" onClick={() => hideCar(data)}>
                <i className="pi pi-eraser p-2" />
            </div>
            
        )
    }

    const header = () => {
        return (
            <div className="flex justify-end">
                <span className="p-input-icon-left mr-2">
                    <i className="pi pi-search" />
                    <InputText value={q} onChange={onSearchChange} placeholder="Cerca targa" />
                </span>
                <Button type="button" icon="pi pi-filter-slash"  className="p-button-outlined" onClick={clearFilter} />
            </div>
        )
    }
    
    const date = (data) => {
        return(
            <div>{(new Date(parseInt(data.datetime))).toLocaleString()}</div>
        )
    }
    
    const counter = (data) => {
        const calcEntranceTime = (timestamp) => {
            var difference = timestamp - Date.now();
            var hours = Math.abs(Math.floor(difference/1000/60/60));
        
            return hours;
        }
        return(
            <div className={'flex items-center'}>
                <div className={'w-4 h-4 rounded-full mr-2 ' + ((calcEntranceTime(data.datetime) > 35) ? 'bg-red-600' : 'bg-green-600')}></div>
                {calcEntranceTime(data.datetime)}
            </div>

        )
    }
    
    const image = (data) => {
        return(
            <img alt="" src={useAxios.getUri().replace('api/','') +  data.imagePath} />
        )
    }
    
    const navigate = useNavigate();
    const [filters,setFilters] = useState([])
    const [cars, setCars] = useState([])
    const [q, setQ] = useState('');
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(50);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tagName, setTagName] = useState('');

    const footer = `Totale di  ${cars ? cars.length : 0} Veicoli.`;
    
    const onPageChange = (event) => {
        console.log(event)
        setPage(event.page);
        setRows(event.rows);
    };
      
    const clearFilter = ()=> {
        setQ('')
    }

    const onSearchChange = (e) => {
        setQ(e.target.value)
    }

    const hideCar = (car) => {
        useAxios.post('car/' + car._id, car).then(res => {
            let filtered = cars.filter(item => item._id !== car._id)
            let _filtered = filters.filter(item => item._id !== car._id)
            setCars(filtered);
            setFilters(_filtered);
        })
    }
    
    const fetchCars = (tagName) => {
        useAxios.get(`parking?tagName=${tagName}&page=${page}&pageSize=${rows}&q=${q}`)
        .then((res) => {
            console.log('cars',totalRecords)
            setCars(res.data.data)
            setTotalRecords(res.data.totalRecords)
        })
        .catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        const tagName = localStorage.getItem('site')
        if (!tagName){
            navigate('/sites')
            return
        }
        // const socket = socketIOClient(useAxios.getUri().replace('/api/',''));
        // socket.on("event added", data => {
        //     if(data.tagName === tagName){
        //         fetchCars(tagName)
        //     }
        // })
        setTagName(tagName)
        fetchCars(tagName)
        // return () => {
        //     socket.disconnect();
        // }
    },[rows,page,q])

    const first = page * rows;

    return (
        <div className="lg:p-12 p-6">
            <div className="text-3xl font-bold mb-6 capitalize">Veicoli {tagName == 'finale_park' ? 'Area Camper' : tagName}</div>
            <div className="datatable-templating">
                <DataTable value={cars} header={header} footer={footer} responsiveLayout="stack">
                    {/* <Column field="_id" header="ID"></Column> */}
                    <Column field="plate" header="Targa"></Column>
                    <Column field="country" header="Paese"></Column>
                    <Column field="datetime" header="Orario d'ingresso" body={date}></Column>
                    <Column  field="datetime" header="Dentro da (h)" body={counter}></Column>
                    <Column field="image" header="Immagine" body={image}></Column>
                    <Column field="id" header="Elimina" body={hide} ></Column>
                </DataTable>
                <Paginator first={first} rows={rows} totalRecords={totalRecords} onPageChange={onPageChange} /> {/* Replace 100 with your total number of records */}
            </div>
            
        </div>
    )
} 

export default ParkingAreaScreen;